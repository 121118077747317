@layer tailwind {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

/* @import "~react-grid-layout/css/styles.css"; */
/* @import "~react-resizable/css/styles.css"; */

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    Segoe UI,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

.normal-mode .ace_cursor {
  background-color: rgba(216, 222, 233, 0.3);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
